/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from "gatsby"
import { RiArrowDownLine, RiArrowRightSLine } from "react-icons/ri"

import PostCard from "./post-card"

const PostMaker = ({ data }) => (
  <section className="home-posts">
    <h2><strong>Products</strong> <span className="icon -right"><RiArrowDownLine/></span></h2>
    <div className="grids col-1 sm-2 lg-2">
      {data}
    </div>
    <Link 
      className="button" 
      to="/product"
      sx={{
        variant: 'links.button'
      }}
    >
      See more<span className="icon -right"><RiArrowRightSLine/></span>
    </Link>
  </section>
)

export default function BlogListHome(props) {
  const data = props.data
  const posts = data.edges
    .filter(edge => !!edge.node.date)
    .map(edge =>
      <PostCard key={edge.node.id} data={edge.node} />
  )
  return <PostMaker data={posts} />
}